<template>
  <Button class="close-app" @click="close">
    <slot>
      Ok
    </slot>
  </Button>
</template>

<script setup lang="ts">
  import { useWebApp } from 'vue-tg'

  const { close } = useWebApp()
</script>

<style scoped lang="scss"></style>
