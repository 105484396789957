<template>
  <Main :initProps>
    <div class="tips">
      <Image src="tips/heart.png" alt="tips" width="200" height="200" />
      <Section>{{ setup.locale.text }}</Section>
      <Form @submit.prevent="submitFormData">
        <InputGroup>
          <template #title>{{ setup.locale.label }}</template>
          <Input type="number" name="amount" v-model="form.amount" />
        </InputGroup>
        <ButtonGroup>
          <Button>{{ setup.locale.button }}</Button>
        </ButtonGroup>
      </Form>
    </div>
  </Main>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';
  import { usePaymentStore } from '@/stores/payment';
  import { useValidatorStore } from '@/stores/validator'

  import { z } from '@/composables/zod-i18n'
  import validatorSchema from '@/config/validatorSchema.json';

  import type { InitControllerData } from '@/types/controller';

  const { setup } = storeToRefs(useControllerStore())
  const initProps = defineProps<InitControllerData>()

  const { setServiceData } = useControllerStore()
  const { setPaymentData } = usePaymentStore()
  const { invoiceState } = storeToRefs(usePaymentStore())

  const { useFormValidator, setFormSchema } = useValidatorStore()
  const { validator, form } = storeToRefs(useValidatorStore())

  const formSchema = z.object({
    amount: z.number().min(validatorSchema.stars.min).max(validatorSchema.stars.max).int(),
    currency: z.literal('tg_stars')
  });

  type FormSchema = z.infer<typeof formSchema>;

  const formData:FormSchema = { amount: 1, currency: 'tg_stars' }

  setFormSchema(formSchema, formData)

  const submitFormData = () => {
    useFormValidator()

    if (validator.value.valid) {
      setPaymentData(form.value)
      setServiceData(form.value, '')
      setup.value.loaded = false
      invoiceState.value.paid.closeApp = true
      invoiceState.value.failed.forceStop = true
    }
  }
</script>

<style scoped lang="scss">
  .tips {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    height: calc(100vh - 2.5rem);

    section {
      margin-bottom: var(--space-medium);
    }

    form {
      width: 100%;
    }
  }
</style>
