<template>
  <Main :initProps>
    <Payments>
      <template v-slot:payment_info>
        <Section class="payment-info">
          <h4>{{ setup.locale.payment_info?.title }}</h4>
          <div>{{ setup.locale.payment_info?.description }}</div>
        </Section>
      </template>
      <template v-slot:details>
        <Section :data="setup.locale.details" />
      </template>
      <template v-slot:prime>
        <Link class="prime-link" :href="'primes'" :as="'button'">{{ setup.locale.prime }}</Link>
      </template>
      <template v-slot:payment-cards>
        <CardContainer :data="setup.locale.methods_data">
          <Card class="payment" v-for="(item, index) in setup.locale.methods" name="payment" :item :index
                v-model="form.payment"
                @change="changePayment"
          />
        </CardContainer>
      </template>
      <template v-slot:amount-cards>
        <CardContainer v-if="setup.data" >
          <Card class="amount" v-for="(item, index) in setup.data[form.payment]" name="amount" :item :index
                :data-label="setup.data.discounts[form.payment][item.id]"
                v-model.number="form.amount"
          />
        </CardContainer>
      </template>
      <template v-slot:additional_info>
        <span>
          {{ setup.locale.additional_info.text }}
          <Link href="/instructions" :data="{ scrollTo: { selector: '#wardy-prime', active: true } }">
            {{ setup.locale.additional_info.link_text }}
          </Link>
        </span>
      </template>
    </Payments>
  </Main>
</template>

<script setup lang="ts">
  import { useTemplateRef, nextTick } from 'vue'
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';
  import type { InitControllerData } from '@/types/controller';

  import { z } from '@/composables/zod-i18n';
  import { useValidatorStore } from '@/stores/validator';

  const { setup } = storeToRefs(useControllerStore())
  const initProps = defineProps<InitControllerData>()

  const { setFormSchema } = useValidatorStore()
  const { form } = storeToRefs(useValidatorStore())

  const formSchema = () => {
    return z.object({
      payment: z.enum(['coins', 'tokens']).default('coins'),
      amount: z.number().int(),
    })
  };

  type FormSchema = z.infer<ReturnType<typeof formSchema>>;

  const formData:FormSchema = { payment: 'coins', amount: 10 }

  const changePayment = async () => form.value.amount = +setup.value.data[form.value.payment][0].id

  setFormSchema(formSchema(), formData)
</script>

<style scoped lang="scss">
  .payment-info div {
    font-size: 24px;
    font-weight: 700;
  }
  .prime-link {
    max-width: unset;
  }
  .payment {
    padding: 14px;
    :deep(.description) {
      margin-top: 0;
    }
  }
  .amount {
    position: relative;
    height: auto;
    min-width: unset;
    padding: var(--space-half);
    text-align: center;
    white-space: nowrap;

    &[data-label]:after {
      content: '-' attr(data-label);
      position: absolute;
      top: -1px;
      right: 0;
      padding: 2px;
      border-radius: 2px;
      background-color: var(--red);
      font-size: 10px;
      transform: translateX(12px);
    }
  }
</style>
