import i18next from 'i18next'
import { z } from 'zod'
import { makeZodI18nMap } from 'zod-i18n-map'

import uk from 'zod-i18n-map/locales/uk-UA/zod.json'
import en from 'zod-i18n-map/locales/en/zod.json'
import customErrorsUk from '@/locales/uk/errors.json'
import customErrorsEn from '@/locales/en/errors.json'

i18next.init({
  lng: 'en',
  resources: {
    uk: {
      zod: uk,
      custom: customErrorsUk
    },
    en: {
      zod: en,
      custom: customErrorsEn
    },
  },
});

z.setErrorMap(makeZodI18nMap({ ns: ['zod', 'custom'] }));

export { z }
