<template>
  <textarea :name :placeholder v-model="model"
            @input="useFieldValidator(name)"
            @focus="clearError(name)"
            @blur="clearError(name)"
  ></textarea>
  <InputError :name />
</template>

<script setup lang="ts">
  import { useValidatorStore } from '@/stores/validator'

  const { useFieldValidator, clearError } = useValidatorStore()

  defineProps<{ name: string, placeholder?: string }>()
  const model = defineModel<string>()
</script>

<style scoped lang="scss">
  textarea {
    max-width: 100%;
    min-height: 50px;
    width: 100%;
    padding: 4px 6px;
    border-radius: var(--radius-half);
    border: var(--input-border);
    background-color: var(--bg-color);
    color: var(--text-color);
    font-size: var(--input-font-size);
    font-weight: 400;
    outline: none;
    transition: all .3s ease;

    &:focus, &:hover {
      border-color: var(--placeholder-color);
    }

    &::placeholder {
      font-size: var(--input-font-size);
      font-weight: 400;
      color: var(--placeholder-color);
    }

    &:disabled {
      background-color: var(--input-disabled-bg-color);
      border-color:  var(--input-disabled-border-color);
      cursor: not-allowed;
    }

    &:has( + .error) {
      border-color: var(--input-error-color);
      box-shadow: 0 0 22.3px 2px rgba(255, 0, 0, 0.2);
    }
  }
</style>
