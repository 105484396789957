<template>
  <button class="center">
    <slot></slot>
  </button>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  button {
    display: block;
    height: 50px;
    width: 100%;
    padding: var(--btn-padding);
    border: 0px;
    align-self: center;
    border-radius: var(--radius);
    background-color: var(--btn-primary-color);
    color: var(--btn-text-color);
    font-size: var(--btn-text-size);
    font-weight: 600;
    text-align: center;
    &:hover {
      background-color: var(--btn-hover-color);
    }
    &:active {
      background-color: var(--btn-active-color);
      box-shadow: 0px 4px 10px 0px rgba(80, 97, 255, 0.5);
    }
  }
</style>
