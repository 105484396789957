import { defineStore, storeToRefs } from 'pinia';
import { useWebAppNavigation, useWebApp } from 'vue-tg'

import { useControllerStore } from '@/stores/controller'

import type { Invoice, Payment } from '@/types/payment'

export const usePaymentStore = defineStore('paymment', () => {
  const payment = ref({})
  const invoiceState = ref({
    paid: {
      closeApp: false
    },
    failed: {
      forceStop: false
    }
  })

  const setPaymentData = (data: Payment) => Object.assign(payment.value, data)

  const setInvoice = (invoice: Invoice) => {
    const { linkInitData } = storeToRefs(useControllerStore())

    const { openInvoice } = useWebAppNavigation()
    const { close } = useWebApp()
    const { setServiceData } = useControllerStore()

    openInvoice(invoice.result, (status) => {
      if (status === 'paid') {
        setServiceData({ ...payment.value, status: 'paid' }, '');

        if (invoiceState.value.paid.closeApp) {
          setTimeout(() => close(), 5000)
        }
      } else if (status === 'cancelled' || status === 'failed') {
        if (invoiceState.value.failed.forceStop) return;

        setServiceData({ ...linkInitData.value });
      }
    })
  }

  return {
    invoiceState,
    payment,
    setPaymentData,
    setInvoice
  }
})
