<template>
  <Link :href :data @click="open">
    <slot></slot>
  </Link>
</template>

<script setup lang="ts">
  import { Link } from '@inertiajs/vue3'

  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';

  const { setup, linkInitData } = storeToRefs(useControllerStore())

  const { href = '', data = {} } = defineProps<{
    href: string
    data?: object | {}
  }>()

  const open = () => {
    setup.value.loaded = false
    linkInitData.value = data
  }
</script>

<style scoped lang="scss">
  button {
    display: block;
    align-self: center;
    text-align: center;
    height: 50px;
    width: 100%;
    padding: var(--btn-padding);
    border-radius: var(--radius);
    font-size: var(--btn-text-size);
    font-weight: 600;
    font-family: 'DMSans', sans-serif;
    color: var(--btn-text-color);
    border: 0px;
    background-color: var(--btn-primary-color);
    margin-top: var(--space);
    &:hover {
      background-color: var(--btn-hover-color);
    }
    &:active {
      background-color: var(--btn-active-color);
      box-shadow: 0px 4px 10px 0px rgba(80, 97, 255, 0.5);
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
</style>
