<template>
  <div class="confirm">
    <Donation v-if="setup.data.group.photo" :url="getImageUrl(setup.data.group.photo)" class="logo" />
    <Logo v-else />
    <div class="wrapper">
      <div class="container">
        <h1>{{ setup.locale.message.header }}</h1>
        <div class="container-info">
          <Section v-html="setup.locale.message.content" :class="'content'" />
          <Section v-if="setup.locale.message.comment_block" :class="'content'">
            <h4>{{ setup.locale.message.comment_title }}</h4>
            {{ setup.locale.message.comment_block }}
          </Section>
          <div class="warning">{{ setup.locale.message.warning }}</div>
        </div>
      </div>
      <ButtonGroup>
        <Button @click.prevent="submitData">{{ setup.locale.message.confirm }}</Button>
      </ButtonGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia'
  import { getImageUrl } from '@/composables/images';
  import { useControllerStore } from '@/stores/controller'

  const { setServiceData } = useControllerStore()
  const { setup } = storeToRefs(useControllerStore())

  const submitData = () => {
    setServiceData(setup.value.locale.data, setup.value.locale.data.type)
  }
</script>

<style scoped lang="scss">
  [data-theme="light"] .confirm .container .container-info .content {
    --comment-color: var(--black);
  }
  .confirm {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    text-align: center;
    height: calc(100dvh - .6rem);
    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 35%;
      margin-bottom: var(--space-medium);
      margin-top: var(--space-half);
    }
    .btn-group {
      width: 100%;
      margin-top: 2.5rem;
    }
    div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 0 0 55%;
      width: 100%;
    }
    img {
      margin: 0 auto;
    }
    h1 {
      margin-bottom: 0;
      font-size: 1.5rem;
      text-align: center;
    }
    h4 {
      margin-top: 0;
    }
    .container {
      padding: var(--space-half);
      width: 100%;
      gap: var(--space-medium);
      .container-info {
        align-items: start;
        width: 100%;
        gap: var(--space-half);
        .warning {
          color: #6B6B6B;
          font-size: 12px;
          font-weight: 600;
        }
        .content {
          --comment-color: #D0D0D0;
          &:nth-child(2) {
            color: var(--comment-color);
          }
        }
      }
    }
  }
</style>
