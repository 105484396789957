import { defineStore } from 'pinia';

import type { UserAuthData } from '@/types/user';

export const useUserStore = defineStore('user', () => {
  const getUserData = (url: string): UserAuthData => {
    let data = Object.fromEntries(new URLSearchParams(url));

    if (data.user) {
      data.user = JSON.parse(decodeURIComponent(data.user));
    }

    return data as unknown as UserAuthData
  }

  return {
    getUserData
  }
})
