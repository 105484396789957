<template>
  <div>
    <Captcha :initProps/>
    <div class="side-bar">
      <ButtonSmall @click="changePage('captcha')" :class="{'active' : !setup.locale?.message}">
        <Icon :name="'like'"/>
      </ButtonSmall>
      <ButtonSmall @click="changePage('warning')" :class="{'active' : setup.locale?.warning}">
        <Icon :name="'warning'"/>
      </ButtonSmall>
      <ButtonSmall @click="changePage('error')" :class="{'active' : setup.locale?.error}">
        <Icon :name="'robot'"/>
      </ButtonSmall>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { InitControllerData } from '@/types/controller';
  import Captcha from '@/view/pages/captchas/Captcha.vue';
  import { useControllerStore } from '@/stores/controller';
  import { storeToRefs } from 'pinia';

  const { setup } = storeToRefs(useControllerStore());

  const initProps = defineProps<InitControllerData>();

  const changePage = async (page: string) => setup.value.locale = setup.value.data[page]
</script>

<style scoped lang="scss">
  div {
    :deep(.close-app) {
      pointer-events: none;
      cursor: pointer;
    }
    .side-bar {
      --btn-active: #353E88;
      position: fixed;
      left: 20px;
      top: 15%;
      display: flex;
      flex-direction: column;
      gap: var(--space-half);
      width: 48px;
      height: 160px;
    }
  }
</style>
