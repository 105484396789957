export const getImageUrl = (url: string): string => {
  if (typeof url !== 'string') {
    console.warn('Invalid URL type, expected a string');
    return '';
  }

  if (url?.includes('telegram.org')) {
    return url
  }

  return new URL(`../assets/images/${url}`, import.meta.url).href;
}
