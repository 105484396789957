<template>
  <div class="message">
    <div class="image">
      <IconAnim v-if="setup.locale.message.animation" :animationName="setup.locale.message.animation" />
      <Logo v-else />
    </div>

    <div class="main-body">
      <div class="text-container">
        <h1>{{ setup.locale.message.title }}</h1>
        <Section v-if="setup.locale.message.description" v-html="setup.locale.message.description" class="content" />
      </div>

      <ButtonGroup>
        <Link v-if="setup.locale.message.link" :href="setup.locale.message.href" :as="'button'"
              :data="{ ...setup.locale.message.data }"
        >
          {{ setup.locale.message.link.text }}
        </Link>
        <ButtonCloseApp>
          {{ setup.locale.message.cancel || 'Ok' }}
        </ButtonCloseApp>
      </ButtonGroup>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';

  const { setup } = storeToRefs(useControllerStore());
</script>

<style scoped lang="scss">
  .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    height: calc(100dvh - 3rem);
    text-align: center;
    .btn-group {
      width: 100%;
    }
    .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: center;
      flex: 0 0 45%;
    }
    .main-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      flex: 0 0 55%;
    }
    .text-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      padding: var(--space);
    }
    img {
      height: 200px;
      width: auto;
    }
    h1 {
      text-align: center;
      margin-bottom: var(--space-medium);
      font-size: 24px;
      font-weight: 700;
    }
    h4 {
      margin-bottom: 0;
      font-size: 28px;
    }
    div {
      display: flex;
      flex-direction: column;
      text-align: start;
    }
  }
</style>
