<template>
  <div v-if="error" class="error">
    {{ error }}
  </div>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useValidatorStore } from '@/stores/validator'

  const { validator } = storeToRefs(useValidatorStore())

  const { name } = defineProps<{
    name: string;
  }>();

  const error = computed(() => {
    const fieldErrors = validator.value.errors?.[name];

    if (fieldErrors && '_errors' in fieldErrors) {
      return fieldErrors._errors?.[0] || '';
    }
  });
</script>

<style scoped lang="scss">
  .error {
    position: absolute;
    top: calc(100% + var(--space));
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 24px;
    width: 100%;
    padding: .5rem 30px;
    border-radius: var(--radius);
    background-color: var(--input-error-bg);
    font-size: var(--input-font-size);
    text-align: center;
    color: var(--black);
    transform: translate(-50%, -8px);
    z-index: 1;

    &:after {
      content: '';
      position: absolute;
      top: -6px;
      left: 50%;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid var(--input-error-bg);
      transform: translateX(-50%);
    }
  }
</style>
