<template>
  <Main :initProps>
    <div class="captcha">
      <Fingerprint :state="iconState" />
      <Loading v-if="loading" />
      <form v-else @submit.prevent="submitFormData">
        <div v-if="setup.locale.verified">
          <label class="verified">{{ setup.locale.verified }}</label>
          <ButtonCloseApp />
        </div>
        <div v-else >
          <div class="label-group">
            <Checkbox v-if="setup.locale.not_robot" id="not-robot" name="notRobot" required />
            <label for="not-robot">
              <span>{{ setup.locale.not_robot }}</span>
            </label>
          </div>
          <Button>{{ setup.locale.submit }}</Button>
        </div>
      </form>
    </div>
  </Main>
</template>

<script setup lang="ts">
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';
  import { useWebAppHapticFeedback } from 'vue-tg';
  import type { InitControllerData } from '@/types/controller';

  const { setServiceData } = useControllerStore()
  const { setup } = storeToRefs(useControllerStore())
  const { notificationOccurred } = useWebAppHapticFeedback()

  const { initProps } = defineProps<{ initProps: InitControllerData }>()
  const loading = ref(false)
  const iconState = ref('')

  const submitFormData = (event: Event) => {
    const formData = new FormData(event.target as HTMLFormElement);
    const checked = formData.has('notRobot');

    if (checked) {
      setTimeout(async () => {
        await setServiceData({ captcha: checked })
        await (() => {
          iconState.value = 'loaded'
          loading.value = false
        })()
      }, 2000 )

      loading.value = true
      iconState.value = 'loading'
    }
  }
</script>

<style scoped lang="scss">
  .captcha {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: end;
    height: calc(100dvh - 100px);
    #loading {
      width: 100%;
    }
    form {
      width: 100%;
    }
    .label-group {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: var(--space-half);
      span {
        display: inline-block;
        font-weight: 600;
        cursor: pointer;
      }
    }

    label.verified {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      margin-bottom: var(--space-half);
    }
  }
</style>
