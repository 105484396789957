<template>
  <label class="input-group">
    <div class="title">
      <slot name="title"></slot>
    </div>
    <slot></slot>
  </label>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  .input-group {
    position: relative;
    display: block;

    .title {
      margin-bottom: var(--space-half);
      color: var(--text-color);
    }
  }
</style>
