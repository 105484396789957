
<template>
  <Main :initProps>
    <h1>{{ setup.locale.title }}</h1>
    <Image :src="setup.locale.img" :alt="setup.locale.title" height="162" width="380" />
    <h2>{{ setup.locale.about_title }}</h2>
    <p>{{ setup.locale.about_desc }}</p>
  
    <AnchorLinks :items="setup.locale.anchor_links" />

    <h2>{{ setup.locale.generals_title }}</h2>
    <p>{{ setup.locale.generals_desc }}</p>

    <Accordion :items="setup.locale.generals" />

    <h4>{{ setup.locale.importants_title }}</h4>
    <TwoSideLine>
      <Accordion :items="setup.locale.importants" />
    </TwoSideLine>
    <h2 id="fan-service">{{ setup.locale.fanservices_title }}</h2>
    <p>{{ setup.locale.fanservices_desc }}</p>

    <Accordion :items="setup.locale.fanservices" />

    <h2 id="moderation">{{ setup.locale.moderations_title }}</h2>
    <p>{{ setup.locale.moderations_desc }}</p>
  
    <Accordion :items="setup.locale.moderations" />
  
    <h2 id="support">{{ setup.locale.support_title }}</h2>
    <div v-html="setup.locale.support_desc"></div>
  </Main>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia';
  import { useControllerStore } from '@/stores/controller';

  import type { InitControllerData } from '@/types/controller';

  const { setup } = storeToRefs(useControllerStore())
  const initProps = defineProps<InitControllerData>()
</script>

<style lang="scss"></style>
