<template>
  <label class="card" >
    <Icon v-if="item.icon" :name="item.icon" />
    <h5>{{ item.title }}</h5>
    <input type="radio" :name :disabled="item.disabled"
           :id="item.id"
           :value="item.id"
           :checked="index === 0"
           v-model="model"
    >
    <span v-if="item.description" class="description">{{ item.description }}</span>
  </label>
</template>

<script setup lang="ts">
  defineProps<{
    item: {
      id: string
      title: string
      icon?: string
      description?: string
      disabled?: 'true' | 'false';
    },
    name: string
    index: number
  }>()

  const model = defineModel<string | number>()
</script>

<style scoped lang="scss">
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 107px;
    min-width: 150px;
    border: 1px solid var(--card-border-color);
    border-radius: var(--radius-half);
    background: linear-gradient(var(--card-bg-color), var(--card-bg-color),var(--card-bg-color)) padding-box,
    linear-gradient(60deg, #8686FF 60%, #AFAEFB 100%, #2322B7 50%) border-box;
    padding: var(--space);
    cursor: pointer;
    &:has(input:disabled) {
      opacity: 0.6;
    }
    &:has(input:checked) {
      box-shadow: 0 0 8.3px var(--card-shadow-color);
      border: 1px solid transparent;
      background: linear-gradient(var(--card-bg-color), var(--card-bg-color),var(--card-bg-color)) padding-box,
      linear-gradient(60deg, #8686FF 60%, #AFAEFB 100%, #2322B7 50%) border-box;
      &::after {
        content: '';
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        background: linear-gradient(60deg, #8686FF 60%, #AFAEFB 100%, #2322B7 50%);
        z-index: -1;
        border-radius: var(--radius-half);
      }
    }
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    .icon {
      width: 40px;
      height: 40px;
    }
    h5 {
      display: block;
      text-align: start;
      font-size: 18px;
      margin: auto 0 0;
    }
    input {
      display: none;
    }
    .description {
      margin-top: var(--space-small);
      font-size: 12px;
      color: #999999;
    }
  }
</style>
