<template>
  <div class="switch-container">
    <slot name="header"></slot>
    <slot></slot>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  .switch-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 0.5rem;
  }
</style>
