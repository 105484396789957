<template>
  <label @click="animation">
    <input type="checkbox" :id :name :required :disabled >
    <span>
      <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z" fill="currentColor"/>
      </svg>
    </span>
    <div class="circle" ref="circle"></div>
  </label>
</template>

<script setup lang="ts">
  defineProps<{
    id: string
    name: string
    required?: boolean | undefined
    disabled?: boolean | undefined
  }>()

  const circle = ref<HTMLDivElement | null>(null);

  const animation = () => {
    if (!circle.value) return;

    circle.value.animate(
      [
        {
          backgroundColor: 'var(--checkbox-hover-color)',
          offset: 0.3,
        },
        {
          backgroundColor: 'var(--checkbox-focus-color)',
          offset: 0.6,
        },
        {
          top: '7px',
          left: '-10px',
          width: '150%',
          height: '150%',
          offset: 0.9,
        },
        {
          backgroundColor: 'transparent',
          transform: 'scale(1)',
          offset: 1,
        },
      ],
      {
        duration: 1000,
        easing: 'ease',
        fill: 'forwards',
      }
    );
  };
</script>

<style scoped lang="scss">
  label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-right: var(--space-half);
    cursor: pointer;
    overflow: hidden;

    .circle {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      z-index: -1;
      pointer-events: none;
    }
    input {
      display: none;
      & + span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border: 2px solid var(--checkbox-color);
        border-radius: 2px;
        svg {
          width: auto;
          height: auto;
          color: transparent;
        }
      }
      &:checked + span {
        background-color: var(--checkbox-color);
        svg {
          color: var(--checkbox-check-color);
        }
      }
      &:disabled {
        opacity: 0.4;
      }
    }
  }
</style>
