import { type Ref } from 'vue'
import axios from 'axios';

import { useUserStore } from '@/stores/user'
import { convertObjectValueBooleanStringToBoolean } from '@/composables/objectConvertors'

export const fetchData = async (url: string, initData: string, token: Ref<string>, options: object = {}) => {
  const { getUserData } = useUserStore()
  const userData = await getUserData(initData);

  try {
    const { data } = await axios.post(url,
      {
        ...userData,
        ...(initData && { initData }),
        options: {
          ...convertObjectValueBooleanStringToBoolean(options)
        }
      },
      {
        headers: {
          'Authorization': `Bearer ${token.value}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (data.locale?.error) {
      console.error('Error fetching data:', data.locale.message);
    }

    return data;
  } catch(error) {
    console.error('Error fetching data:', error);
  }
}
