import { useNotificationStore } from '@/stores/notification'

export const copyCommandTextToBuffer = (event: MouseEvent, copyText: string) => {
  const target = event.target as HTMLElement;

  if (target.nodeName !== 'CODE' || target.classList.contains('no-copy')) {
    return;
  }

  const { setNotification } = useNotificationStore()
  const formatText = (text: string): string => {
    text = text.replace(/\n/g, ' ').replace(/\s+/g, ' ');

    if (text.length > 12) {
      text = `${text.slice(0, 12).trim()}...`;
    }

    return text;
  }

  const text = (target.textContent) ? target.textContent.trim(): '';
  navigator.clipboard.writeText(text)

  setNotification(`${copyText} ${formatText(text)}`)
}
