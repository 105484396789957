<template>
  <Main :initProps>
    <div class="roleplay">
      <h1>{{ setup.locale.title }}</h1>
      <ProfileInfo
        :img="setup.data.photo"
        :title="setup.data.title"
      />
      <TabsHeader>
        <TabButton class="active" :tab="setup.locale.tabs.add" />
        <TabButton :tab="setup.locale.tabs.activity" @click="notification" />
      </TabsHeader>
      <TabContent :id="setup.locale.tab_add.id">
        <Form>
          <h3>{{ setup.locale.tab_add.trigger.title }}</h3>
          <InputGroup>
            <Textarea name="trigger" v-model="form.trigger"
                      :placeholder="setup.locale.tab_add.trigger.placeholders[form.type]"
            />
          </InputGroup>
          <SwitchContainer>
            <SwitchRadio name="type" v-model="form.type" :data="setup.locale.tab_add.trigger.switch" />
          </SwitchContainer>
          <h3>{{ setup.locale.tab_add.response.title }}</h3>
          <p class="example" v-html="setup.locale.tab_add.trigger.example"></p>
          <InputGroup>
            <Textarea name="format" v-model="form.format" :placeholder="setup.locale.tab_add.response.placeholder" />
          </InputGroup>
          <h3>{{ setup.locale.tab_add.kind.title }}</h3>
          <SwitchContainer>
            <SwitchRadio name="kind" v-model="form.kind" :data="setup.locale.tab_add.kind.switch" />
          </SwitchContainer>
          <h3>{{ setup.locale.tab_add.privacy.title }}</h3>
          <SwitchContainer>
            <SwitchRadio name="private" v-model="form.private" :boolean="true"
              :data="setup.locale.tab_add.privacy.private"
            />
            <SwitchRadio name="citizenship" v-model="form.citizenship" :boolean="true"
              :data="setup.locale.tab_add.privacy.citizenship"
            />
          </SwitchContainer>
        </Form>
      </TabContent>
    </div>
    <ButtonGroup>
      <Button @click.prevent="submitFormData">{{ setup.locale.buttons.create }}</Button>
      <ButtonCloseApp>{{ setup.locale.buttons.close }}</ButtonCloseApp>
    </ButtonGroup>
  </Main>
</template>

<script lang="ts" setup>
  import { storeToRefs } from 'pinia'
  import { useControllerStore } from '@/stores/controller'
  import { useNotificationStore } from '@/stores/notification'
  import { useValidatorStore } from '@/stores/validator'

  import { z } from '@/composables/zod-i18n'
  import { validateEmojiLength, validateOnlyEmoji } from '@/composables/validators/emoji'
  import validatorSchema from '@/config/validatorSchema.json';

  import type { InitControllerData } from '@/types/controller'

  const { setup } = storeToRefs(useControllerStore())
  const { setServiceData } = useControllerStore()
  const { setNotification } = useNotificationStore()
  const initProps = defineProps<InitControllerData>()

  const { useFormValidator, setFormSchema } = useValidatorStore()
  const { validator, form } = storeToRefs(useValidatorStore())

  const formSchema = () => {
    return z.object({
      trigger: z.string().superRefine((value, ctx) => {
        const result = createTriggerSchema(form.value.type).safeParse(value)

        if (!result.success) {
          ctx.addIssue(result.error.issues[0])
        }

        return result.success
      }),
      type: z.enum(['text', 'emoji']).default('text'),
      format: z.string().min(2).max(150)
          .refine((value) => value.match(new RegExp(validatorSchema.regexp.users)), {params: {i18n: 'error_users_interaction'}}),
      kind: z.enum(['regular', 'nsfw']).default('regular'),
      private: z.enum(['true', 'false']).default('false'),
      citizenship: z.enum(['true', 'false']).default('false')
    })
  }

  type FormSchema = z.infer<ReturnType<typeof formSchema>>;

  const formData:FormSchema = {
    trigger: '',
    type: 'text' as 'text',
    format: '',
    kind: 'regular',
    private: 'false',
    citizenship: 'false'
  }

  setFormSchema(formSchema(), formData)

  const createTriggerSchema = (type: 'text' | 'emoji') => {
    let schema;

    if (type === 'text') {
      schema = z.string().min(2).max(15)
    } else if (type === 'emoji') {
      schema = z.string().refine((value) => validateOnlyEmoji(value), { params: { i18n: 'error_only_emoji' } })
    }

    return schema!.refine((value) => validateEmojiLength(value), { params: { i18n: 'error_one_emoji' } })
  }

  const submitFormData = async () => {
    useFormValidator()

    if (validator.value.valid) {
      await setServiceData(form.value, '')

      await queueMicrotask(() => {
        setNotification(setup.value.data.notification)
        form.value.trigger = ''
        form.value.format = ''
      });
    }
  }

  const notification = () => setNotification(setup.value.locale.tabs.activity.message, 2000);
</script>

<style lang="scss">
  .roleplay {
    h1 {
      margin: 1rem 0 2rem;
      font-family: 'Roboto';
      font-size: 24px;
      font-weight: 700;
      text-align: center;
    }
    .example {
      color: #6B6B6B;
      font-size: 0.75rem;
      font-weight: 500;
      line-height: 1rem;
      code {
        padding: 0;
        color: var(--hyper-text-color);
        font-size: 0.75rem;
        font-weight: 500;
        background-color: transparent;
      }
    }
    textarea[name="responseText"] {
      min-height: 80px;
    }
  }
</style>
