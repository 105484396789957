<template>
  <section class="section">
    <h4 v-if="props.data?.title">{{ props.data.title }}</h4>
    <div v-if="props.data?.description" v-html="props.data.description"></div>
    <slot></slot>
  </section>
</template>

<script setup lang="ts">
  interface Props {
    data?: {
      title?: string
      description?: string
    }
  }

  const props = defineProps<Props>()
</script>

<style lang="scss">
  [data-theme="light"] .content {
    --amount-color: #3F3F3F;
  }

  .section {
    width: 100%;
    padding: var(--space-half);
    border-radius: var(--radius);
    background-color: var(--bg-preset-color-20);
    &:has(.switch) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4 {
        margin: 0;
      }
    }
    h4 {
      margin: 0 0 var(--space) 0;
    }
  }

  .section.cards-wrap {
    h4 {
      padding-left: var(--space);
    }
  }
  .content {
    --amount-color: #9697A3;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    gap: var(--space-half);
    width: 100%;
    padding: var(--space);
    background-color: var(--section-bg-color);
    white-space: wrap;
    font-size: 12px;
    font-weight: 400;
    div {
      font-size: 14px;
      font-weight: 600;
      a {
        all: unset;
        cursor: pointer;
        color: #42A9C0;
        font-size: 14px;
        font-weight: 600;
      }
      b {
        font-size: 14px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        font-family: 'Kode Mono';
        color: var(--amount-color);
      }
    }
    h4 {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: var(--text-color);
    }
  }
</style>
