import { defineStore } from 'pinia';
import { useWebAppHapticFeedback } from 'vue-tg';

export const useNotificationStore  = defineStore('nofication', () => {
  const { notificationOccurred } = useWebAppHapticFeedback()
  const open = ref(false);
  const message = ref('');

  const setNotification = (text: string, timeout: number = 1000) => {
    open.value = true;
    message.value = text;
    notificationOccurred('success')

    setTimeout(() => open.value = false, timeout);
  }

  return {
    open,
    message,
    setNotification
  }
})
