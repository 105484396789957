<template>
  <div class="preloader">
    <Logo width="300" height="300" />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  .preloader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100dvh;
    color: var(--tg-theme-text-color);
    img {
      animation: colorWave 1.2s infinite linear;
    }
  }
</style>
